
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations générales">
      <b-row>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Nom"
            label-for="basicInput"
          >
            <b-form-input
             required
              id="basicInput"
              v-model="form.name"
              :plaintext="readOnly"
              placeholder="Nom"
            />
          </b-form-group>

          <b-form-group
              label="Ordre d'affichage"
              label-for="Ordre d'affichage"
            >
           
            
              <b-form-input
                id="Ordre d'affichage"
                v-model="form.order_display"
                :plaintext="readOnly"
                type="number"
                step="1"
                placeholder="Ordre d'affichage"
               
              />
             
            </b-form-group>
            
        </b-col>
      </b-row>

    </b-card>

    <b-button v-if="edition==false"
     @click="createTintCategory"
    >
    Enregistrer
    </b-button>

    <b-button v-else
     @click="editTintCategory"
    >
    Enregistrer
    </b-button>

  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput,BOverlay, BRow, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest} from '@simb2s/senseye-sdk'

export default {
  name: 'TintCategoryForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BOverlay,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect
  },
  data() {
      return {
          edition:false,
          loading:true,
          currentBase64:null,
          file: null,
          form:{
            name:"",
            file:'',
            price:0,
            order_display:null,
            photo:''
          },
      }
  },
  props: ['tint_category', 'readOnly'],
  methods:{
    async createTintCategory()
    {
      this.loading=true
      try {
       
        await APIRequest.create('tint-categories',this.form)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La catégorie de teinte a bien été créée !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push({name:'tint-categories'})
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    },
    async editTintCategory()
    {
      this.loading=true
      try {
       
        await APIRequest.update('tint-categories',this.tint_category.id,this.form)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La catégorie de teinte a bien été modifiée !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push({name:'tint-categories'})
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    },
 
  },

  
    
  async mounted(){
   if(this.tint_category!=null)
    {
      this.form.name = this.tint_category.name
      this.form.price = this.tint_category.price
      this.form.photo = this.tint_category.photo
      this.form.file = this.tint_category.file
      this.form.order_display = this.tint_category.order_display

      this.edition=true
    }
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>